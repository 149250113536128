import IconLoad from 'components/Icons/IconLoad'
import IconThumbsDown from 'components/Icons/IconThumbsDown'
import IconThumbsDownFilled from 'components/Icons/IconThumbsDownFilled'
import IconThumbsUp from 'components/Icons/IconThumbsUp'
import IconThumbsUpFilled from 'components/Icons/IconThumbsUpFilled'
import LineLoader from 'components/Loaders/LineLoader'
import theme from 'theme'

import * as S from './styles'

export default function Button(props) {
  const { onClick, loading, disabled, children, outlined, ...rest } = props

  const mainColor = disabled ? '#b6bbc2' : '#ff6500'

  const handleOnClick = () => {
    if (!disabled && onClick) {
      onClick()
    }
  }

  if (loading) {
    return (
      <S.Button loading={+loading} outlined={outlined} {...rest}>
        <LineLoader
          color={
            outlined
              ? theme.colors.secondary(500, '55')
              : theme.colors.white(500, '55')
          }
        />
      </S.Button>
    )
  } else {
    return (
      <S.Button
        onClick={handleOnClick}
        disabled={disabled}
        outlined={outlined}
        mainColor={mainColor}
        {...rest}
      >
        {children}
      </S.Button>
    )
  }
}

const SubmitBtn = ({ label, onClick, ...rest }) => {
  const { type, Ico, isLoading, id, disabled, color } = { ...rest }
  if (type)
    return (
      <S.SubmitButton
        {...rest}
        disabled={disabled}
        id={id || 'botaoSubmit'}
        onClick={(e) => onClick(e)}
      >
        {isLoading ? (
          <IconLoad className='loading' style={{ fill: color, width: 24 }} />
        ) : (
          label
        )}
      </S.SubmitButton>
    )

  return (
    <S.SubmitButton
      {...rest}
      id={id || 'botaoSubmit'}
      onClick={(e) => onClick(e)}
      disabled={isLoading}
    >
      {Ico}
      {label}
    </S.SubmitButton>
  )
}

const CardBtn = (props) => {
  const { label, onClick, color, margin, fontSize, id, disabled } = props
  return (
    <S.CardButton
      id={id || ''}
      disabled={disabled}
      onClick={() => onClick()}
      color={color}
      margin={margin}
      fontSize={fontSize}
    >
      {label}
    </S.CardButton>
  )
}

const VoteBtn = ({ onClick, downVote, status, disabled }) => {
  return (
    <S.VoteButton disabled={disabled} onClick={() => onClick()}>
      {downVote ? (
        !status ? (
          <IconThumbsDown />
        ) : (
          <IconThumbsDownFilled />
        )
      ) : !status ? (
        <IconThumbsUp />
      ) : (
        <IconThumbsUpFilled />
      )}
    </S.VoteButton>
  )
}

export { SubmitBtn, CardBtn, VoteBtn }
