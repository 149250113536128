const IconThumbsDownFilled = () => (
  <svg
    width='14'
    height='14'
    viewBox='0 0 14 14'
    fill='none'
    xmlns='https://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.1386 8.52786H11.8886C12.7441 8.52786 13.4163 7.85564 13.4163 6.96953V6.93897L12.8052 2.08064C12.8052 1.25564 12.1025 0.583415 11.2775 0.583415H6.99967C6.17467 0.583415 5.31912 0.797304 4.58579 1.16397L3.63856 1.6223V5.46263C3.63856 6.61399 4.04222 7.72892 4.7793 8.61342C5.62169 9.62427 6.08301 10.8985 6.08301 12.2143V12.5001C6.08301 13.0195 6.51079 13.4167 6.99967 13.4167C7.15245 13.4167 7.30523 13.3556 7.45801 13.2945C8.4969 12.6834 9.13856 11.1251 9.13856 10.0556V9.52786C9.13856 8.97557 9.58628 8.52786 10.1386 8.52786ZM0.888563 7.91675H2.7219C2.90523 7.91675 3.02745 7.79453 3.02745 7.61119V0.88897C3.02745 0.705638 2.90523 0.583415 2.7219 0.583415H0.888563C0.70523 0.583415 0.583008 0.705638 0.583008 0.88897V7.61119C0.583008 7.79453 0.70523 7.91675 0.888563 7.91675Z'
      fill='#FF6500'
    />
  </svg>
)

export { IconThumbsDownFilled as default }
