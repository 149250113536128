import styled, { css } from 'styled-components'

export const Button = styled.button`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px
    ${({ mainColor, theme }) => mainColor || theme.colors.secondary(400)};
  background-color: ${({ outlined, mainColor, theme }) =>
    outlined ? theme.colors.white(500) : mainColor};
  user-select: none;
  color: ${({ outlined, mainColor, theme }) =>
    outlined ? mainColor : theme.colors.white(500)};
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  transition: background-color 0.3s;
  border-radius: ${({ theme }) => theme.borderRadius.md};

  svg {
    margin-right: 10px;
  }

  ${({ loading, disabled, outlined, mainColor, theme }) =>
    !loading && !disabled
      ? `&:hover {
        cursor: pointer;
        color: ${
          outlined ? theme.colors.secondary(400) : theme.colors.white(500)
        };
        background-color: ${
          outlined ? theme.colors.white(500) : theme.colors.secondary(400)
        };
        border: solid 1px ${mainColor || theme.colors.secondary(400)};
        path {
          fill: ${
            outlined ? theme.colors.secondary(400) : theme.colors.white(500)
          };
        }

  }`
      : 'pointer-events: none'}
`

export const SubmitButton = styled.button`
  width: ${(props) => props.size || '153px'};
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 32px;
  font-weight: 700;
  color: ${(props) => props.color || '#ff6500'};
  background-color: ${(props) => props.bgColor || '#fff'};
  border: 1px solid #ff6500;
  cursor: pointer;
  margin-left: ${(props) => props.marginLeft};
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
  margin-right: ${(props) => props.marginRight};
  border-radius: ${(props) => props.theme.borderRadius.md};

  svg {
    margin-right: 8px;
  }
`

export const CardButton = styled.button`
  background-color: transparent;
  border: 0;
  text-decoration: underline;
  margin: 0 ${(props) => props.margin || 8}px;
  font-size: ${(props) => props.fontSize || 14}px;
  font-weight: 700;
  line-height: 14px;
  text-transform: uppercase;
  color: ${(props) => props.color || '#7F858D'};
  cursor: pointer;
`

export const VoteButton = styled.button`
  transform: ${(props) =>
    props.role === 'downVote' ? 'rotate(180deg)' : 'rotate(0deg)'};
  background-color: transparent;
  border: 0;
  cursor: pointer;
  margin-right: 4px;
  display: flex;
`
